import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useTransition, animated } from "react-spring";
import ReactGA from 'react-ga';
import Dialog from 'rc-dialog';
import slugify from 'slugify';
import 'rc-dialog/assets/index.css';
import clsx from 'clsx';
import useDebounce from './use-debounce';
import { Spinner } from './playing-now';
import { useHistory } from 'react-router-dom';

function useSlugAvailability(slug) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setData(null);
      setError(false);

      try {
        const response = await fetch(`https://s6q60if5n2.execute-api.eu-west-1.amazonaws.com/qa/stations/checkSlug/${slug}`);
        const body = await response.json();
        setData(body);
        setLoading(false);
        setError(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    }
    if (slug && slug.length > 2) {
      fetchData();
    }
    else {
      setLoading(false);
      setData(null);
      setError(false);
    }
  }, [slug]);

  return { loading, data, error };
}

function Header({ station, activeUser }) {
  const slugTouched = useRef(false);
  const [submitLoading, setLoading] = useState(false);
  const [stationCreated, setStationCreated] = useState(false);
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const debouncedSlug = useDebounce(slug, 300);
  const history = useHistory();

  useEffect(() => {
    if (!slugTouched.current) {
      setSlug(slugify(name, {
        lower: true,
        strict: true
      }));
    }
  }, [name]);

  useEffect(() => {
    if (!slug) {
      slugTouched.current = false;
    }
  }, [slug])

  const handleSlugInputChange = ({ target }) => {
    slugTouched.current = true;
    setSlug(slugify(target.value, {
      lower: true,
      strict: true
    }));
  }

  const handleFormSubmit = (e) => {
    ReactGA.event({
      category: 'Station',
      action: 'Create'
    });
    setLoading(true);
    fetch('https://s6q60if5n2.execute-api.eu-west-1.amazonaws.com/qa/stations', {
      body: JSON.stringify({
        name,
        slug,
        description
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      method: 'POST'
    })
      .then(data => data.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          setStationCreated(true);
        }
        else {
          handleDialogClose();
        }
      });

    e.preventDefault();
  }

  const isSubmitAllowed = () => {
    return name.length > 2 && slug.length > 2 && name.length < 100 && slug.length < 100 && data && data.available && !submitLoading && debouncedSlug === slug;
  }

  const handleDialogClose = () => {
    setCreateStationOpen(false);
    setStationCreated(false);
    setName('');
    setSlug('');
    setDescription('');
  }

  const { loading, data } = useSlugAvailability(debouncedSlug)

  const handleRedirect = () => {
    history.push(`/s/${slug}`);
    handleDialogClose();
  }



  const [isCreateStationOpen, setCreateStationOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const handleInviteClick = () => {
    const tempInput = document.createElement("input");
    tempInput.value = window.location.href;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, [2000]);

    ReactGA.event({
      category: 'Station',
      action: 'Invite'
    });
  }

  const transitions = useTransition(copySuccess, null, {
    from: { transform: 'translateY(-10px)', opacity: 0 },
    enter: { transform: 'translateY(0px)', opacity: 1 },
    leave: { transform: 'translateY(-10px)', opacity: 0 },
    unique: true,
    config: {
      duration: 100,
    },
  });

  return (
    <Fragment>
      <div className="mobile-disclaimer">
        Sorry, we can play coRadio music only on desktop devices.
      </div>
      <header className="header">
        <div className="header-left">
          <div className="logo">
            <img src="/logo.svg" alt="coradio" />
            <h1>coRadio <span>C-19-D</span></h1>
          </div>
          {activeUser && (
            <button className="create-station-button" onClick={() => setCreateStationOpen(true)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8Z" />
              </svg>
              Create Station
            </button>
          )}

        </div>
        <Dialog
          className="create-station-dialog"
          animation="zoom"
          onClose={handleDialogClose}
          visible={isCreateStationOpen}
        >
          <div className="container">
            {!stationCreated ? (
              <Fragment>
                <h1>Create Your Station</h1>
                <form onSubmit={handleFormSubmit} className="create-station-form">
                  <div className="form-group">
                    <label className="form-label">Station Name</label>
                    <input className="form-input" value={name} onChange={({ target }) => setName(target.value)} placeholder="Choose a cool name ;-)" />
                  </div>
                  <div className="form-group">
                    <label className="form-label">URL</label>
                    <div className="form-input-wrapper form-input-wrapper-inline">
                      <div className="form-input-prefix">coradio.co/s/</div>
                      <input className="form-input" value={slug} onChange={handleSlugInputChange} placeholder="choose-a-cool-name" />
                    </div>
                    <div className="form-input-feedback">
                      {
                        (!loading && data) && (
                          data.available ? (
                            <Fragment>
                              <img className="form-input-feedback-icon" src="/checkmark.svg" alt="Success" /> URL available! All yours...
                            </Fragment>
                          ) : (
                              <Fragment>
                                <img className="form-input-feedback-icon" src="/Clear.svg" alt="Error" /> URL unavailable... Tweak it until you find one available, and then grab it fast.
                              </Fragment>
                            )
                        )
                      }
                      {
                        loading && <Fragment>
                          <div className="feedback-loader-container"><Spinner size="small" theme="light" /></div>
                      Checking...
                    </Fragment>
                      }

                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">About</label>
                    <textarea className="form-input" value={description} onChange={({ target }) => setDescription(target.value)} placeholder="Tell us why you are opening this station instead of watching Netflix?" />
                  </div>
                  <div className="form-actions">
                    <button type="submit" style={{ marginRight: 8 }} disabled={!isSubmitAllowed()} className={clsx({
                      "button": true
                    })}>Create Station</button>
                    <button onClick={handleDialogClose} className="button-transparent">
                      Cancel
                    </button>
                  </div>
                </form>
              </Fragment>
            ) : (
                <div className="station-created-container">
                  <div className="station-created-video">
                    <video autoPlay="true" loop muted>
                      <source src="/new_station.mp4" type="video/mp4" />
                    </video>
                  </div>
                  <h1>Your Station is Ready! How Cool!</h1>
                  <button onClick={handleRedirect} className="button">Please Come In</button>
                </div>
              )}

          </div>
        </Dialog>
        <div className="station-selector">
          {Boolean(station) && station.name}
        </div>
        <div className="active-user">
          <a className="social-icon" href="mailto:yes@coradio.co" target="_blank" rel="noopener noreferrer">
            <img src="/Email.svg" alt="Email" />
          </a>
          <a className="social-icon" href="https://twitter.com/Coradioapp" target="_blank" rel="noopener noreferrer">
            <img src="/Twitter.svg" alt="Twitter" />
          </a>
          <a className="social-icon" href="https://www.instagram.com/coradioapp/" target="_blank" rel="noopener noreferrer">
            <img src="/Insta.svg" alt="Instagram" />
          </a>
          <a className="social-icon" href="https://fb.me/coradioapp" target="_blank" rel="noopener noreferrer">
            <img src="/Facebook.svg" alt="Facebook" />
          </a>
          <button className="button invite-button" onClick={handleInviteClick}>
            <img src="/share.svg" alt="Share" />
            <span>Invite</span>
          </button>
          {transitions.map(({ item, key, props }) =>
            item ? (
              <animated.div key={key} style={props}>
                <div style={{ top: 24, right: 0 }} className="toast">
                  <img className="toast-icon" src="/checkmark.svg" alt="Succcess" />
                  <span>URL Copied to Clipboard</span>
                </div>
              </animated.div>
            ) : null
          )}

          {Boolean(activeUser && activeUser.avatar) ? <img src={activeUser.avatar} alt={activeUser.name} className="active-user-profile-image" /> : <div></div>}
        </div>
      </header>
    </Fragment>
  )
}

export default Header;
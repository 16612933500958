import React from 'react';

function FreeAccountDialog({ onClose }) {
    return (
        <div className="backdrop">
            <div className="dialog">
                <div className="dialog-header">
                    <img src="/dialog-header.png" width="268" alt="" />
                </div>
                <div className="dialog-content">
                    <div className="title">Get your Spotify Subscription</div>
                    <p>You need to subscribe to Spotify to play music. Any scubscription will do. </p>
                    <a href="https://www.spotify.com/premium/" target="blank" className="button" style={{ marginBottom: 8 }}>Get Your Spotify Subscription</a>
                    <button onClick={onClose} target="blank" className="alternative-button">Stare at Coradio without music</button>
                </div>
            </div>
        </div>
    )
}

export default FreeAccountDialog;
import React, { Fragment } from 'react';

function Listeners({ listeners }) {
  return (
    <div className="listeners">
      {Boolean(listeners.length) && (
        <Fragment>
          <div className="subheading">{listeners.length === 1 ? 'One Listener' : `${listeners.length} Listeners`}</div>
          <div className="listener-items">
            {listeners.map((item, i) => (
              <div key={i} className="listener-item">
                <div className="listener-status"></div>
                {
                  item.avatar ?
                    <img src={item.avatar} alt={item.name} className="listener-image" /> :
                    <div className="listener-image-placeholder"><span>{item.name[0]}</span></div>
                }
                <div className="listener-name">{item.name}</div>
              </div>
            ))}
          </div>
        </Fragment>
      )}
      {Boolean(!listeners.length) && (
        <Fragment>
          <div className="subheading">Listeners</div>
          <div className="listener-item">
            <div className="listener-status listener-status-placeholder"></div>
            <div className="listener-image-placeholder"></div>
            <div className="listener-name"></div>
          </div>
        </Fragment>
      )}
    </div>

  )
}

export default Listeners;
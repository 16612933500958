import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import { Emoji } from 'emoji-mart';
import clsx from 'clsx';
import * as SpotifyApi from './Spotify/api';
import uniqBy from 'lodash/uniqBy';
import shuffle from 'lodash/shuffle';
import Header from './header';
import Listeners from './listeners';
import TrackItem from './track-item';
import PlayingNow from './playing-now';
import ActivityLog from './activity-log';
import FreeAccountDialog from './free-account-dialog';
import WebPlaybackReact from './Spotify/WebPlaybackReact.js';
import loginActions from './Spotify/Login.js';
import NewTrackNotification from './new-track-notification';
import useTracks from './use-tracks';
import useDebounce from './use-debounce';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css';
import 'rc-notification/assets/index.css';
import './App.css';
import { formatDistance } from 'date-fns';

ReactGA.initialize('UA-161588339-1');
const baseUrl = 'https://s6q60if5n2.execute-api.eu-west-1.amazonaws.com/qa';
// const baseUrl = 'https://6eb52z1zp9.execute-api.eu-west-1.amazonaws.com/staging';
const socket = new WebSocket('wss://emyiwjun3d.execute-api.eu-west-1.amazonaws.com/qa');
// const socket = new WebSocket('wss://kvbtxdekml.execute-api.eu-west-1.amazonaws.com/staging');

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function TrackItemHistory({ user, startedAt, children }) {
  return (
    <div className="track-item-history">
      <div className="track-item-history-header">
        <div className="track-item-history-avatar">
          {user.id === '-1' && <img src='/bot.png' alt="" />}
          {user.id !== '-1' && (
            user.avatar ? <img src={user.avatar} alt="" /> : <span>{user.name ? user.name[0] : ''}</span>
          )}
        </div>
        <span>{user.id === '-1' ? 'coRadio Recommendations' : user.name} {formatDistance(new Date(startedAt), new Date())}</span>
      </div>
      <div className="track-item-history-content">
        {children}
      </div>
    </div>
  )
}

function Station() {
  const ref = useRef(null)
  let { slug: stationSlug } = useParams();
  const playerRef = useRef();
  const [isWaiting, setIsWaiting] = useState(true);
  const [hideTracks, setHideTracks] = useState(() => !!JSON.parse(localStorage.getItem('coradio-hide-tracks')));
  const [playerReady, setPlayerReady] = useState(false);
  const [socketState, setSocketState] = useState('');
  const [activeUser, setActiveUser] = useState();
  const [activeTab, setActiveTab] = useState('next');
  const [station, setStation] = useState();
  const [messages, setMessages] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [query, setQuery] = useState('');
  const [listeners, setListeners] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [savedTracks, setSavedTracks] = useState([]);
  const [savedTracksMap, setSavedTracksMap] = useState({});
  const { playingTrack, playedTracks, queue, setQueueTracks } = useTracks(tracks);
  const [playingTrackLikes, setPlayingTrackLikes] = useState({
    id: null,
    likes: [],
    unlikes: []
  });
  const prevPlayingTrack = usePrevious(playingTrack);
  const [playerState, setPlayerState] = useState(null);
  const [userAccessToken, setUserAccessToken] = useState(() => localStorage.getItem('token') ? localStorage.getItem('spotify_token') : '');
  const [userDeviceId, setUserDeviceId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [playerLoaded, setPlayerLoaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [playerSelected, setPlayerSelected] = useState(false);
  const [freeAccountDialogOpen, setFreeAccountDialogOpen] = useState(false);
  const [playerVolume, setPlayerVolume] = useState(100);
  const [playerMuted, setPlayerMuted] = useState(false);
  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (playingTrack) {
      setPlayingTrackLikes({
        id: playingTrack.id,
        likes: (playingTrack.social || []).filter(item => item.like),
        unlikes: (playingTrack.social || []).filter(item => !item.like)
      });
    }
    else {
      setPlayingTrackLikes({
        id: null,
        likes: [],
        unlikes: []
      });
    }
  }, [playingTrack, setPlayingTrackLikes]);

  const sendSocketMessage = useCallback(({ type, data, ...rest }) => {
    if (!station) {
      return;
    }

    socket.send(JSON.stringify({
      token: localStorage.getItem('token'),
      action: "message",
      message: {
        type: type,
        data: data,
        station: station.id,
        ...rest
      }
    }));
  }, [station]);

  const onAccessTokenExpiration = useCallback(() => {
    localStorage.setItem('spotify_token', '');
    setUserAccessToken(null);
    setPlayerLoaded(false);
    setPlayerSelected(false);
    setPlayerState(null);
    setUserDeviceId(null);
  }, []);

  const setVolume = useCallback((nextVolume) => {
    setPlayerVolume(nextVolume);
  }, []);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('token');

    async function refreshAccessToken() {
      const response = await fetch(`${baseUrl}/auth/spotify/token?refresh_token=${refreshToken}`);
      const json = await response.json();

      if (json && json.access_token && json.expires_in) {
        localStorage.setItem('spotify_token', json.access_token);
        localStorage.setItem('expires_in', Date.now() + json.expires_in * 1000);

        setUserAccessToken(json.access_token);
        setTimeout(refreshAccessToken, json.expires_in * 1000 - 60000);
      }
    }

    if (refreshToken && token) {
      refreshAccessToken();
    }

  }, []);

  useEffect(() => {
    const isSavedTrack = async function (id) {
      const response = await SpotifyApi.isSavedTrack(userAccessToken, id);
      setSavedTracksMap({
        ...savedTracksMap,
        [id]: response[0]
      })
    }

    if (userAccessToken && playingTrack && !savedTracksMap.hasOwnProperty(playingTrack.data.id)) {
      isSavedTrack(playingTrack.data.id);
    }
  }, [playingTrack, savedTracksMap, userAccessToken]);

  useEffect(() => {
    if (playerReady) {
      if (playerMuted) {
        playerRef.current.setVolume(0);
      }
      else {
        playerRef.current.setVolume(playerVolume / 100);
      }
    }
  }, [playerVolume, playerMuted, playerReady]);

  const onSuccessfulAuthorization = useCallback(({ accessToken, refreshToken, expiresIn, token }) => {
    if (!token || !refreshToken || !accessToken || !expiresIn) return;

    localStorage.setItem('token', token);
    localStorage.setItem('spotify_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('expires_in', Date.now() + expiresIn * 1000);

    setUserAccessToken(accessToken);
    if (playerRef.current) {
      playerRef.current.connect()
    }
  }, [setUserAccessToken]);

  const onMessage = useCallback(async (event) => {
    const action = JSON.parse(event.data);
    const { type, user } = action.message;

    if (!type) return;

    if (type === 'success:queue:update') {
      const { data } = action.message;
      return setQueueTracks(data);
    }

    if (type === 'success:track:like') {
      const { data, user, like } = action.message;
      if (playingTrack.id === data) {
        if (like) {
          setPlayingTrackLikes({
            ...playingTrackLikes,
            likes: [...playingTrackLikes.likes, { user, like }]
          })
        }
        else {
          setPlayingTrackLikes({
            ...playingTrackLikes,
            unlikes: [...playingTrackLikes.unlikes, { user, like }]
          })
        }
      }
      return;
    }

    const refreshTracks = async () => {
      const response = await fetch(`${baseUrl}/stations/${stationSlug}/tracks`);
      const tracks = await response.json();
      setTracks(tracks.items);
    }

    if (type === 'track:autoAdd' || type === 'track:next') {
      refreshTracks();
    }

    if (!playingTrack && !tracks.length) {
      refreshTracks();
    }

    if (type === 'listener:add') {
      if (!station || !user) return;
      const nextListeners = uniqBy([user, ...listeners], (e) => e.id);
      setListeners(nextListeners)
    }

    if (type === 'chat:message') {
      if (!station || !user) return;

      setMessages([...messages, { ...action.message, createdAt: new Date().getTime() }]);
    }

  }, [messages, listeners, station, stationSlug, setQueueTracks, playingTrack, playingTrackLikes, tracks]);

  const playCurrentTrack = useCallback(async () => {
    if (!playingTrack || !userAccessToken) {
      return;
    }
    const now = new Date().getTime();
    const positionMs = now - playingTrack.startedAt;
    await SpotifyApi.play(
      {
        trackId: playingTrack.data.uri,
        deviceId: userDeviceId,
        positionMs
      },
      userAccessToken
    );
  }, [playingTrack, userDeviceId, userAccessToken])

  useEffect(() => {
    if ((!prevPlayingTrack && playingTrack) || (prevPlayingTrack && playingTrack && prevPlayingTrack.id !== playingTrack.id)) {
      playCurrentTrack()
    }
    if (!playingTrack) {
      SpotifyApi.pause(userAccessToken).then(() => { });
    }
  }, [playingTrack, prevPlayingTrack, userAccessToken, playCurrentTrack]);

  useEffect(() => {
    const fetchAndAddRecommendationsToQueue = async () => {
      async function getRecommendations() {
        const response = await SpotifyApi.getRecommendations(userAccessToken, playedTracks.map(item => item.data).splice(0, 5));
        if (response.error) {
          return onAccessTokenExpiration();
        }
        return response.tracks;
      }

      // async function getPlaylistTracks() {
      //   const response = await SpotifyApi.getPlaylistTracks(userAccessToken, '0ywQCGEAxh0wfpV7pT33S6');
      //   if (response.error) {
      //     return onAccessTokenExpiration();
      //   }
      //   return shuffle(response.items.map(item => item.track));
      // }

      const recommendations = await getRecommendations();

      sendSocketMessage({
        type: 'track:autoAdd',
        data: recommendations.map(item => item.id).splice(0, 5),
        station: station.id
      });
    }

    if (queue.length === 0 && playedTracks.length > 0 && savedTracks.length > 0 && station && userAccessToken) {
      fetchAndAddRecommendationsToQueue();
    }
  }, [playedTracks, queue, station, stationSlug, savedTracks, userAccessToken, sendSocketMessage, onAccessTokenExpiration]);

  useEffect(() => {
    if (!playerState && playingTrack && userAccessToken && userDeviceId) {
      playCurrentTrack();
    }
  }, [playingTrack, userAccessToken, userDeviceId, playerState, playCurrentTrack]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!station || tracks.length === 0) {
        return;
      }

      if (!playingTrack) {
        return userAccessToken && sendSocketMessage({ type: 'track:next' });
      }

      const endAt = playingTrack.startedAt + playingTrack.data.duration_ms;
      const now = new Date().getTime();
      if (now > endAt) {
        sendSocketMessage({
          type: 'track:next'
        });
      }

    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [userAccessToken, playingTrack, tracks, station, sendSocketMessage]);

  useEffect(() => {
    socket.onopen = () => setSocketState('open');
    socket.onclose = () => { };
    socket.onmessage = onMessage;
    socket.onerror = () => { };

    if (activeUser || station) {
      ReactGA.set({
        user: activeUser ? activeUser.name : undefined,
        station: station ? station.name : undefined
      });
    }
  }, [onMessage, station, activeUser]);

  useEffect(() => {
    if (socketState === 'open' && station) {
      sendSocketMessage({
        type: 'listener:add',
        user: activeUser
      });
    }
  }, [socketState, activeUser, station, sendSocketMessage])

  useEffect(() => {
    async function fetchStation() {
      const response = await fetch(`${baseUrl}/stations/${stationSlug}`);
      const station = await response.json();

      if (station.error) {
        return;
      }

      const listeners = uniqBy(station.listeners, (e) => e.id).sort((a, b) => b.createdAt - a.createdAt);
      const messages = station.messages.sort((a, b) => a.createdAt - b.createdAt);

      setStation(station);
      setMessages(messages);
      setListeners(listeners)
      setTracks(station.tracks);
    }
    fetchStation();
  }, [stationSlug]);

  useEffect(() => {
    if (station) {
      document.title = `coRadio - ${station.name}`;
    }
  }, [station]);

  useEffect(() => {
    window.spotifyCallback = (payload) => {
      onSuccessfulAuthorization(payload);
      window.spotifyAuthPopup.close()
    }

  }, [onSuccessfulAuthorization, onAccessTokenExpiration]);

  useEffect(() => {
    async function getUserProfile() {
      const response = await SpotifyApi.getProfile(userAccessToken);
      if (response.error) {
        return onAccessTokenExpiration();
      }
      setActiveUser({
        id: response.id,
        name: response.display_name,
        avatar: '',
        // avatar: response.images.length ? response.images[0].url : '',
        premium: response.product === 'premium'
      });

      if (response.product !== 'premium') {
        setFreeAccountDialogOpen(true);
      }
    }

    async function getSavedTracks() {
      const response = await SpotifyApi.getSavedTracks(userAccessToken);
      if (response.error) {
        return onAccessTokenExpiration();
      }
      setSavedTracks(response.items.map(item => item.track));
    }

    async function getPlaylistTracks() {
      const response = await SpotifyApi.getPlaylistTracks(userAccessToken, '0ywQCGEAxh0wfpV7pT33S6');
      if (response.error) {
        return onAccessTokenExpiration();
      }
      setSavedTracks(shuffle(response.items.map(item => item.track)));
    }

    if (userAccessToken) {
      getUserProfile();
      (stationSlug === 'dancing-flyers') ? getPlaylistTracks() : getSavedTracks();
    }

  }, [userAccessToken, onAccessTokenExpiration, stationSlug]);

  const handleSearchChange = async ({ target }) => {
    setQuery(target.value);
    if (!target.value) return setSearchResults([]);
  }

  useEffect(() => {
    const debounceSearch = async () => {
      const response = await SpotifyApi.search(debouncedQuery, userAccessToken);
      if (response.error) {
        return onAccessTokenExpiration();
      }
      setSearchResults(response.tracks.items.filter(item => item.duration_ms < 1200000));

      ReactGA.event({
        category: 'User',
        action: 'Search Tracks',
        value: debouncedQuery
      });
    }
    if (debouncedQuery) {
      debounceSearch();
    }
  }, [debouncedQuery, userAccessToken, onAccessTokenExpiration]);

  const handleAddTrack = async (track, history = false) => {
    if (!track || !station || !activeUser) {
      return;
    }

    // dont allow to add same song for when it already in the queue
    // if (queue.some(t => t.data.id === track.id)) {
    //   return;
    // }

    const { album: { images }, duration_ms } = track;
    const album_image = images.length ? images[0].url : '';

    if (duration_ms > 1200000) {
      return;
    }

    ref.current(album_image);

    sendSocketMessage({
      type: 'track:add',
      data: track.id
    });

    ReactGA.event({
      category: 'User',
      action: history ? 'Add track from history' : 'Add Track'
    });
  }

  const handleSendChatMessage = (chatMessage) => {
    if (!chatMessage || !station || !activeUser) {
      return;
    }
    sendSocketMessage({
      type: 'chat:message',
      data: chatMessage,
    });

    ReactGA.event({
      category: 'User',
      action: 'Send Chat Message'
    });
  }

  const handleDeleteTrack = ({ id, station, createdAt }) => {
    sendSocketMessage({
      type: 'track:delete',
      data: { station, createdAt },
    });

    ReactGA.event({
      category: 'User',
      action: 'Delete Track'
    });
  }

  const webPlaybackSdkProps = {
    playerName: "coRadio",
    playerInitialVolume: 1.0,
    playerRefreshRateMs: 1000,
    playerAutoConnect: Boolean(userAccessToken),
    onPlayerRequestAccessToken: (() => userAccessToken),
    onPlayerLoading: (() => setPlayerLoaded(true)),
    onPlayerWaitingForDevice: (data => { setPlayerSelected(false); setUserDeviceId(data.device_id) }),
    onPlayerDeviceSelected: (() => setPlayerSelected(true)),
    onPlayerError: (playerError => console.error(playerError)),
    onPlayerInstanceSetup: (playerInstance => { playerRef.current = playerInstance; setPlayerReady(true) })
  };

  useEffect(() => {
    if (playerState && isWaiting) {
      setIsWaiting(false);
    }
  }, [playerState, isWaiting]);

  const handleToggleSave = useCallback(async () => {
    if (userAccessToken) {
      if (!savedTracksMap[playingTrack.data.id]) {
        await SpotifyApi.saveTrack(playingTrack.data.id, userAccessToken);
      }
      else {
        await SpotifyApi.unsaveTrack(playingTrack.data.id, userAccessToken);
      }

      setSavedTracksMap({
        ...savedTracksMap,
        [playingTrack.data.id]: !savedTracksMap[playingTrack.data.id]
      })
      ReactGA.event({
        category: 'User',
        action: 'Save track'
      });
    }
  }, [playingTrack, savedTracksMap, userAccessToken]);

  const isMajorLiked = useCallback(() => {
    if (playingTrackLikes.likes.length > 0 && playingTrackLikes.likes.length === playingTrackLikes.unlikes.length) {
      return true;
    }
    return playingTrackLikes.likes.length > (playingTrackLikes.likes.length + playingTrackLikes.unlikes.length) / 2;
  }, [playingTrackLikes]);

  const isMajorUnliked = useCallback(() => {
    if (playingTrackLikes.likes.length > 0 && playingTrackLikes.likes.length === playingTrackLikes.unlikes.length) {
      return true;
    }
    return playingTrackLikes.unlikes.length > (playingTrackLikes.likes.length + playingTrackLikes.unlikes.length) / 2;
  }, [playingTrackLikes]);

  const getLikeHeight = () => {
    if (playingTrackLikes.likes.length === 0) return 0;
    return playingTrackLikes.likes.length / (playingTrackLikes.likes.length + playingTrackLikes.unlikes.length) * 60
  };

  const getUnlikeHeight = () => {
    if (playingTrackLikes.unlikes.length === 0) return 0;
    return playingTrackLikes.unlikes.length / (playingTrackLikes.likes.length + playingTrackLikes.unlikes.length) * 60
  };

  const isUserVoted = useCallback(() => {
    return !!playingTrackLikes.likes.find(i => i.user.id === activeUser.id) || !!playingTrackLikes.unlikes.find(i => i.user.id === activeUser.id)
  }, [activeUser, playingTrackLikes])

  const handleLike = useCallback((like) => {
    if (!station || !activeUser || !playingTrack) {
      return;
    }

    sendSocketMessage({
      type: 'track:like',
      data: {
        id: playingTrack.id,
        createdAt: playingTrack.createdAt,
        like: like
      },
      trackId: playingTrack.id
    });

    ReactGA.event({
      category: 'User',
      action: like ? 'Like track' : 'Unlike track'
    });
  }, [activeUser, station, playingTrack, sendSocketMessage]);

  return (
    <WebPlaybackReact
      onPlayerStateChange={(playerState) => setPlayerState({ ...playerState })}
      {...webPlaybackSdkProps}
    >
      <div className="App">
        <Header station={station} activeUser={activeUser} />
        <aside className="left-sidebar scrollbar">
          {
            userAccessToken && (
              <Fragment>
                <input
                  dir="auto"
                  type="search"
                  value={query}
                  onChange={handleSearchChange}
                  className="input search-spotify-input"
                  placeholder="Search for songs, artist or playlists"
                />
                <div className="subheading">{!query ? 'Recommendations' : 'Results'}</div>
                <div className="recommendations">
                  {(query ? searchResults : savedTracks).map((track, index) => (
                    <TrackItem
                      key={track.id}
                      track={track}
                      clickable
                      onClick={() => handleAddTrack(track)}
                      renderActions={(
                        <button className="queue-item-button">
                          <img src='/Add.svg' alt="Add" />
                        </button>
                      )}
                    />
                  ))}
                </div>
              </Fragment>
            )
          }
          {
            !userAccessToken && (
              <div className="teaser">
                <div>
                  <img src="/teaser.svg" alt="" />
                  <div className="teaser-heading">Come join the fever!</div>
                  <button className="button connect-spotify-button" onClick={() => loginActions.logInWithSpotify()}>
                    <img src="/spotify.svg" alt="Spotify" />
                    <span>Connect with Spotify</span>
                  </button>
                </div>
              </div>
            )
          }

        </aside>
        <aside className="right-sidebar scrollbar">
          <Listeners listeners={listeners} />
          <ActivityLog
            messages={messages}
            playedTracks={playedTracks}
            playingTrack={playingTrack}
            isLoggedIn={Boolean(userAccessToken)}
            onMessageSubmit={handleSendChatMessage}
          />
        </aside>
        <main className="main">
          <div className="playing-now">
            <PlayingNow
              data={playingTrack}
              activeUser={activeUser}
              isLoggedIn={!!userAccessToken}
              isWaiting={isWaiting}
              isSaved={playingTrack && savedTracksMap[playingTrack.data.id]}
              onToggleSave={handleToggleSave}
            />
            {
              Boolean(userAccessToken) && (
                <div className="audio-control">
                  <img src={playerMuted || playerVolume === 0 ? '/Mute.svg' : '/Audio.svg'} alt='' onClick={() => setPlayerMuted(!playerMuted)} />
                  <Slider className="audio-control-slider" value={playerMuted ? 0 : playerVolume} defaultValue={100} vertical onChange={setVolume} />
                </div>
              )
            }
            <div className="social-like-controls">
              <div className="social-like-control">
                {
                  playingTrackLikes.likes.length ? (
                    <Tooltip placement="left" overlay={
                      <div className="tooltip-content">
                        <div className="tooltip-title">{playingTrackLikes.likes.length} {playingTrackLikes.likes.length === 1 ? 'Like' : 'Likes'}</div>
                        {playingTrackLikes.likes.map(item => <div key={item.user.id}>{item.user.name}</div>)}
                      </div>
                    }>
                      <svg onClick={() => !isUserVoted() && handleLike(true)} style={{ fill: playingTrackLikes.likes.find(i => i.user.id === activeUser?.id) ? '#1371ff' : 'white' }} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1778 17.8053C16.6108 17.764 16.6647 17.264 17.1995 17.17C18.0879 17.014 18.4785 16.5607 18.4785 16.1413C18.4785 15.7887 18.2013 15.46 17.7098 15.354C17.1239 15.228 17.245 14.8493 17.7315 14.8127C18.524 14.7527 18.9132 14.3033 18.9132 13.8727C18.9132 13.496 18.6157 13.134 18.0305 13.0593C17.4305 12.9827 17.6258 12.57 18.0522 12.4447C18.4169 12.338 19 12.1113 19 11.438C19 10.99 18.65 10.3967 17.4102 10.4447C16.5506 10.478 14.8432 10.3127 13.9247 9.85467C14.5597 7.41667 14.4071 4 12.7438 4C11.6279 4 11.4165 5.20467 11.0812 6.31267C9.87854 10.292 7.47182 11.0207 5 11.3813V18C9.68183 18 10.9503 20 14.454 20C16.6934 20 17.8505 18.8433 17.8505 18.2227C17.8498 18 17.6594 17.8413 17.1778 17.8053Z" />
                      </svg>
                    </Tooltip>
                  ) : (
                      <svg onClick={() => !isUserVoted() && handleLike(true)} style={{ fill: playingTrackLikes.likes.find(i => i.user.id === activeUser?.id) ? '#1371ff' : 'white' }} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1778 17.8053C16.6108 17.764 16.6647 17.264 17.1995 17.17C18.0879 17.014 18.4785 16.5607 18.4785 16.1413C18.4785 15.7887 18.2013 15.46 17.7098 15.354C17.1239 15.228 17.245 14.8493 17.7315 14.8127C18.524 14.7527 18.9132 14.3033 18.9132 13.8727C18.9132 13.496 18.6157 13.134 18.0305 13.0593C17.4305 12.9827 17.6258 12.57 18.0522 12.4447C18.4169 12.338 19 12.1113 19 11.438C19 10.99 18.65 10.3967 17.4102 10.4447C16.5506 10.478 14.8432 10.3127 13.9247 9.85467C14.5597 7.41667 14.4071 4 12.7438 4C11.6279 4 11.4165 5.20467 11.0812 6.31267C9.87854 10.292 7.47182 11.0207 5 11.3813V18C9.68183 18 10.9503 20 14.454 20C16.6934 20 17.8505 18.8433 17.8505 18.2227C17.8498 18 17.6594 17.8413 17.1778 17.8053Z" />
                      </svg>
                    )
                }

                <div className={clsx({ "bar": true, "bar-like": isMajorLiked() })}>
                  <div className="inner" style={{ height: getLikeHeight() }} />
                </div>
              </div>
              <div className="social-unlike-control">
                {
                  playingTrackLikes.unlikes.length ? (
                    <Tooltip placement="left" overlay={
                      <div className="tooltip-content">
                        <div className="tooltip-title">{playingTrackLikes.unlikes.length} {playingTrackLikes.unlikes.length === 1 ? 'Dislike' : 'Dislikes'}</div>
                        {playingTrackLikes.unlikes.map(item => <div key={item.user.id}>{item.user.name}</div>)}
                      </div>
                    }>
                      <svg onClick={() => !isUserVoted() && handleLike(false)} style={{ fill: playingTrackLikes.unlikes.find(i => i.user.id === activeUser?.id) ? '#ff4f28' : 'white' }} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.82219 6.19467C7.38922 6.236 7.33532 6.736 6.80049 6.83C5.91215 6.986 5.52153 7.43934 5.52153 7.85867C5.52153 8.21134 5.79874 8.54 6.29017 8.646C6.8761 8.772 6.75499 9.15067 6.26847 9.18734C5.47603 9.24734 5.08681 9.69667 5.08681 10.1273C5.08681 10.504 5.38432 10.866 5.96955 10.9407C6.56948 11.0173 6.37417 11.43 5.94785 11.5553C5.58313 11.662 5 11.8887 5 12.562C5 13.01 5.35002 13.6033 6.58978 13.5553C7.44943 13.522 9.15681 13.6873 10.0753 14.1453C9.44033 16.5833 9.59293 20 11.2562 20C12.3721 20 12.5835 18.7953 12.9188 17.6873C14.1215 13.708 16.5282 12.9793 19 12.6187V6C14.3182 6 13.0497 4 9.54603 4C7.30662 4 6.14946 5.15667 6.14946 5.77734C6.15016 6 6.34057 6.15867 6.82219 6.19467Z" />
                      </svg>
                    </Tooltip>
                  ) : (
                      <svg onClick={() => !isUserVoted() && handleLike(false)} style={{ fill: playingTrackLikes.unlikes.find(i => i.user.id === activeUser?.id) ? '#ff4f28' : 'white' }} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.82219 6.19467C7.38922 6.236 7.33532 6.736 6.80049 6.83C5.91215 6.986 5.52153 7.43934 5.52153 7.85867C5.52153 8.21134 5.79874 8.54 6.29017 8.646C6.8761 8.772 6.75499 9.15067 6.26847 9.18734C5.47603 9.24734 5.08681 9.69667 5.08681 10.1273C5.08681 10.504 5.38432 10.866 5.96955 10.9407C6.56948 11.0173 6.37417 11.43 5.94785 11.5553C5.58313 11.662 5 11.8887 5 12.562C5 13.01 5.35002 13.6033 6.58978 13.5553C7.44943 13.522 9.15681 13.6873 10.0753 14.1453C9.44033 16.5833 9.59293 20 11.2562 20C12.3721 20 12.5835 18.7953 12.9188 17.6873C14.1215 13.708 16.5282 12.9793 19 12.6187V6C14.3182 6 13.0497 4 9.54603 4C7.30662 4 6.14946 5.15667 6.14946 5.77734C6.15016 6 6.34057 6.15867 6.82219 6.19467Z" />
                      </svg>
                    )
                }

                <div className={clsx({ "bar": true, "bar-unlike": isMajorUnliked() })}>
                  <div className="inner" style={{ height: getUnlikeHeight() }} />
                </div>
              </div>
            </div>
          </div>
          <div className="queue-management">
            <div className="queue-management-tabs">
              <div className="track-notification-wrapper">
                <NewTrackNotification children={add => (ref.current = add)} />
              </div>
              <button
                className={clsx({
                  'queue-management-tab-link': true,
                  'active': activeTab === 'next'
                })}
                onClick={() => setActiveTab('next')}
              >
                Up Next
              </button>
              <button
                className={clsx({
                  'queue-management-tab-link': true,
                  'active': activeTab === 'history'
                })}
                onClick={() => setActiveTab('history')}
              >
                History
              </button>
            </div>
            {
              activeTab === 'next' && (
                <div className="queue-list">
                  <div className="queue-list-actions">
                    <button className="link-button" onClick={() => { localStorage.setItem('coradio-hide-tracks', !hideTracks); setHideTracks(!hideTracks) }}>
                      <img className="icon" src={hideTracks ? "/Show.svg" : "/Hide.svg"} alt="Hide" />
                      <span>{hideTracks ? 'Reveal Songs' : 'Hide Songs'}</span>
                    </button>
                  </div>
                  {queue.map((item, index) => {
                    const isRemovable = Boolean(activeUser && (item.user.name === activeUser.name || item.user.id === '-1'));
                    const isAddedByCurrentUser = Boolean(activeUser && (item.user.name === activeUser.name));
                    const isTrackHidden = !isAddedByCurrentUser && hideTracks;

                    return (
                      <TrackItem
                        key={item.id}
                        queue
                        isAddedByCurrentUser={isAddedByCurrentUser}
                        hide={isTrackHidden}
                        user={item.user}
                        track={item.data}
                        order={index + 1}
                        onClick={() => !isTrackHidden && isRemovable && handleDeleteTrack(item)}
                        clickable={!isTrackHidden && isRemovable}
                        renderActions={
                          !isTrackHidden && isRemovable && (
                            <button className="queue-item-button">
                              <img src='/Remove.svg' alt="Remove" />
                            </button>
                          )
                        }
                      />
                    )
                  })}
                </div>
              )
            }
            {
              activeTab === 'history' && (
                <div className="queue-list">
                  {playedTracks.map((item, index) => (
                    <TrackItemHistory key={item.id} user={item.user} startedAt={item.startedAt}>
                      <TrackItem
                        track={item.data}
                        clickable={!!userAccessToken}
                        renderActions={
                          Boolean(activeUser) && (
                            <button
                              className="queue-item-button"
                              onClick={() => handleAddTrack(item.data, true)}
                            >
                              <img src='/Add.svg' alt='Add' />
                            </button>
                          )
                        }
                      />
                    </TrackItemHistory>
                  ))}
                </div>
              )
            }
          </div>
        </main>
      </div>
      {
        Boolean(activeUser && activeUser.premium && freeAccountDialogOpen) && (
          <FreeAccountDialog onClose={() => setFreeAccountDialogOpen(false)} />
        )
      }

      <div className="love">
        Made with
        <span style={{ verticalAlign: 'middle', margin: '0 4px' }}><Emoji emoji='heart' set='apple' size={14} /></span>
        from
        <span style={{ verticalAlign: 'middle', margin: '0 4px' }}><Emoji emoji='flag-il' set='apple' size={14} /></span>
        while the
        <span style={{ verticalAlign: 'middle', margin: '0 4px' }}><Emoji emoji='earth_asia' set='apple' size={14} /></span>
        is losing it each and every day.
      </div>
    </WebPlaybackReact>
  )
}

export default Station;
import React, { Fragment, useRef, useState, useLayoutEffect, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import TrackItem from './track-item';
import { formatDistance } from 'date-fns';

const emojiesMap = {
  "<3": "\u2764\uFE0F",
  "</3": "\uD83D\uDC94",
  ":D": "\uD83D\uDE00",
  ":)": "\uD83D\uDE42",
  ";)": "\uD83D\uDE09",
  ":(": "\uD83D\uDE12",
  ":p": "\uD83D\uDE1B",
  ";p": "\uD83D\uDE1C",
  ":'(": "\uD83D\uDE22"
};

function escapeSpecialChars(regex) {
  return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
}

export function SendIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8451 11.3125C19.3662 11.6227 19.3662 12.3773 18.845 12.6874L6.20917 20.2085C5.67591 20.5259 5 20.1416 5 19.521L5 14.7385C5 14.2995 5.28632 13.9119 5.70591 13.7828L10.2575 12.3823C10.634 12.2664 10.634 11.7335 10.2575 11.6177L5.70591 10.2172C5.28632 10.0881 5 9.7004 5 9.2614L5 4.47894C5 3.85836 5.67591 3.47409 6.20917 3.79149L18.8451 11.3125Z" />
    </svg>
  )
}

export const ActivityLogMessageItem = React.memo(({ message, track }) => {
  return (
    <Tooltip overlayClassName="message-tooltip" getTooltipContainer={() => document.querySelector(".activity-log")} mouseEnterDelay={1} placement="top" overlay={
      <div className="tooltip-content">
        <div className="tooltip-title">{formatDistance(new Date(message.createdAt), new Date())} ago {track ? 'while playing' : ''}</div>
        {
          track && (
            <div className="tooltip-track">
              <TrackItem track={track?.item?.data} />
            </div>
          )
        }

      </div>
    }>
      <div className="activity-log-item">
        <div className="activity-log-item-thumb">
          {message.user.avatar ? <img src={message.user.avatar} alt={message.user.name} /> : <span>{message.user.name[0]}</span>}
        </div>
        <div className="activity-log-item-details">
          <div className="message-author">{message.user && message.user.name}</div>
          <div className="message-content">{message.data}</div>
        </div>
      </div>
    </Tooltip>
  )
});

export const ActivityLogListenerItem = React.memo(({ message }) => {
  return (
    <div className="activity-log-item">
      <div className="activity-log-item-thumb">
        {message.user.avatar ? <img src={message.user.avatar} alt={message.user.name} /> : <span>{message.user.name[0]}</span>}
      </div>
      <div className="activity-log-item-details">
        <div className="message-content">
          {message.user.name} joined the station
          </div>
      </div>
    </div>
  )
})

export const ActivityLogTrackStart = React.memo(({ track, user }) => {
  let {
    name: track_name,
    artists: [{
      name: artist_name,
    }],
    album: {
      name: album_name,
      images: albumImages
    }
  } = track;

  const album_image = albumImages.length ? albumImages[0].url : '';

  return (
    <div className="activity-log-item">
      <div className="activity-log-item-thumb">
        <img src={album_image} alt={album_name} />
      </div>
      <div className="activity-log-item-details">
        <div className="message-content">
          {track_name} by {artist_name} started playing
        </div>
      </div>
    </div>
  )
})

function ActivityLog({ messages, playedTracks, playingTrack, isLoggedIn, onMessageSubmit }) {
  const [chatMessage, setChatMessage] = useState('');
  const activityLogRef = useRef();
  const initScrollDown = useRef();

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const activities = [];
    activities.push(...messages.map(item => ({ ...item, timestamp: item.createdAt })));
    activities.push(...playedTracks.map(item => ({ type: 'track:start', item, timestamp: item.startedAt })));
    if (playingTrack) {
      activities.push({ type: 'track:start', item: playingTrack, timestamp: playingTrack.startedAt });
    }

    const next = activities.sort((a, b) => a.timestamp - b.timestamp).reduce((result, item, index) => {
      if (item.type === 'chat:message') {
        result.push(item);
      }
      if (item.type === 'track:start' && activities[index + 1] && activities[index + 1].type !== 'track:start') {
        result.push(item);
      }

      return result;
    }, []);

    setActivities(next);

  }, [messages, playedTracks, playingTrack]);

  useLayoutEffect(() => {
    if (activities.length && !initScrollDown.current) {
      activityLogRef.current.scrollTop = activityLogRef.current.scrollHeight;
      initScrollDown.current = true;
    }
  }, [activities]);

  const handleSendChatMessage = (e) => {
    if (!chatMessage) {
      return;
    }
    setChatMessage('');
    onMessageSubmit(chatMessage);
    e.preventDefault();
  }

  const handleMessageChange = ({ target }) => {
    let value = target.value;
    for (var i in emojiesMap) {
      var regex = new RegExp(escapeSpecialChars(i), 'gim');
      value = value.replace(regex, emojiesMap[i]);
    }
    setChatMessage(value);
  }

  return (
    <Fragment>
      <div className="activity-log scrollbar" ref={activityLogRef}>
        <div className="activity-log-items">
          {activities.map((item, i) => {
            if (item.type === 'chat:message') {
              const messageTrack = [...[...activities].slice(0, i).reverse()].find(item => item.type === 'track:start');
              return (
                <ActivityLogMessageItem
                  key={i}
                  message={item}
                  track={messageTrack}
                />
              )
            }
            else if (item.type === 'track:start') {
              return (
                <ActivityLogTrackStart
                  key={i}
                  track={item.item.data}
                  user={item.item.user}
                />
              )
            }
            return null;
          })}
        </div>
      </div>
      <div className="chat-message">
        <form onSubmit={handleSendChatMessage}>
          <input dir="auto" type="text" className="chat-input" placeholder="Say What?" value={chatMessage} onChange={handleMessageChange} />
          <button type="submit" className="chat-message-button" disabled={!isLoggedIn}>
            <SendIcon />
          </button>
        </form>
      </div>
    </Fragment>
  )
}

export default ActivityLog;
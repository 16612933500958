import React from 'react';
import clsx from 'clsx';

const TrackItem = React.memo(({ track, user, queue, order, hide, isAddedByCurrentUser, renderActions, clickable, ...rest }) => {
  let {
    name: track_name,
    artists: [{
      name: artist_name,
    }],
    album: {
      name: album_name,
      images: albumImages
    }
  } = track;

  const album_image = albumImages.length ? albumImages[0].url : '';

  return (
    <div
      className={clsx({
        'recommendation-item': true,
        'recommendation-item-clickable': clickable
      })}
      {...rest}
    >
      {Boolean(queue && isAddedByCurrentUser) && (
        <div className="recommendation-user recommendation-user-avatar">
          {Boolean(user.avatar) && <img src={user.avatar} alt={user.name} />}
          {!Boolean(user.avatar && user.name) && <span>{user.name[0]}</span>}
        </div>
      )}
      {Boolean(queue && !isAddedByCurrentUser) && (
        <div className="recommendation-user">
          {order}
        </div>
      )}
      {(album_image && !hide) && <img src={album_image} className="recommendation-cover" alt={album_name} />}
      {(!album_image || hide) && <div className="recommendation-cover" />}
      <div className="recommendation-details">
        <div className="recommendation-title">
          {!hide && track_name}
        </div>
        <div className="recommendation-subtitle">
          {!hide && artist_name}
        </div>
      </div>
      {Boolean(renderActions) && renderActions}
    </div>
  )
});

export default TrackItem;
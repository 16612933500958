import { useState, useEffect, useCallback } from "react";

function useTracks(tracks) {
  const [queue, setQueue] = useState([]);
  const [playedTracks, setPlayedTracks] = useState([]);
  const [playingTrack, setPlayingTrack] = useState();

  useEffect(
    () => {
      const queue = tracks
        .filter(item => item.status === 'waiting')
        .sort((a, b) => a.order - b.order);

      setQueue(queue);
    },
    [tracks]
  );

  useEffect(
    () => {
      const history = tracks
        .filter(item => item.status === 'played')
        .sort((a, b) => b.startedAt - a.startedAt);

      setPlayedTracks(history);
    },
    [tracks]
  );

  useEffect(
    () => {
      const playingTrack = tracks
        .find(item => item.status === 'playing');

      if (playingTrack) {
        setPlayingTrack(playingTrack);
      }
      else if (!tracks.length) {
        setPlayingTrack(null);
      }
    },
    [tracks]
  );

  const setQueueTracks = useCallback((data) => {
    setQueue(data.sort((a, b) => a.order - b.order));
  }, []);

  return { queue, playedTracks, playingTrack, setQueueTracks };
}

export default useTracks;
import React, { Fragment } from 'react';
import clsx from 'clsx';

export function Spinner({ className, size, theme, ...props }) {
  return <div className={clsx({
    loader: true,
    [`loader-${size}`]: !!size,
    [`loader-${theme}`]: !!theme,
    [className]: !!className
  })} {...props}></div>
}

function PlayingNow({ data, activeUser, isSaved, isWaiting, isLoggedIn, onToggleSave }) {
  if (!data) {
    return <Fragment>
      <div className="playing-now-author">
        <div className="playing-now-author-image">
        </div>
        <span></span>
      </div>
      <div className="playing-now-cover">
      </div>
      <div className="now-playing-details">
        <div className="now-playing-name"></div>
        <div className="now-playing-artist"></div>
      </div>
    </Fragment>
  }

  let {
    name: track_name,
    artists: [{
      name: artist_name,
    }],
    album: {
      name: album_name,
      images: albumImages
    }
  } = data.data;

  const album_image = albumImages.length ? albumImages[0].url : '';

  return <Fragment>
    <div className="playing-now-author">
      <div className="playing-now-author-image">
        {data.user.id === '-1' && <img src='/bot.png' alt="" />}
        {data.user.id !== '-1' && (
          data.user.avatar ? <img src={data.user.avatar} alt="" /> : <span>{data.user.name ? data.user.name[0] : ''}</span>
        )}
      </div>
      <span>{data.user.id === '-1' ? 'coRadio Recommendations' : (activeUser && data.user.id === activeUser.id ? 'You' : data.user.name) + ' played'}</span>
    </div>
    <div className={clsx({
      "playing-now-cover": true,
      "playing-now-cover-loading": isWaiting && isLoggedIn
    })}>
      <img src={album_image} alt={album_name} />
      {Boolean(isWaiting && isLoggedIn) && <div className="playing-now-cover-spinner"><Spinner /></div>}
      <div className="playing-now-save-backdrop" onClick={onToggleSave}>
        <button className={isSaved ? 'active' : ''}>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6.36228C9.639 2.43358 3 3.58048 3 8.50336C3 11.8932 7.17825 15.3593 12 20C16.8225 15.3593 21 11.8932 21 8.50336C21 3.56376 14.3438 2.46267 12 6.36228Z" />
          </svg>
        </button>
      </div>
    </div>
    <div className="now-playing-details">
      <div className="now-playing-name">{track_name}</div>
      <div className="now-playing-artist">{artist_name}</div>
    </div>
  </Fragment>
}

export default PlayingNow;
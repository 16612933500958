import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from './landing-page';
import Station from './station';

function SpotifyCallback() {
  if (window.opener) {
    const urlParams = new URLSearchParams(window.location.search);
    window.opener.spotifyCallback({
      accessToken: urlParams.get('access_token'),
      refreshToken: urlParams.get('refresh_token'),
      expiresIn: urlParams.get('expires_in'),
      token: urlParams.get('token')
    })
  }

  return <div></div>
}



function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={'/'}>
          <LandingPage />
        </Route>
        <Route exact path={'/callback'}>
          <SpotifyCallback />
        </Route>
        <Route path={`/s/:slug`}>
          <Station />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

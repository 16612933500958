import React from 'react';
import { Link } from "react-router-dom";

function LandingPage() {
    return (
        <div className="landing-page-wrapper">
            <div className="background-video">
                <video loop muted autoPlay>
                    <source src="/site_background.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="landing-page-container">
                <div className="landing-header">
                    <div className="logo">
                        <img width="82" src="/logo.svg" alt="coradio" />
                        <h1>coRadio</h1>
                    </div>
                    <div className="social">
                        <a className="social-icon" href="mailto:yes@coradio.co" target="_blank" rel="noopener noreferrer">
                            <img src="landing/Email.svg" alt="Email" />
                        </a>
                        <a className="social-icon" href="https://twitter.com/Coradioapp" target="_blank" rel="noopener noreferrer">
                            <img src="landing/Twitter.svg" alt="Twitter" />
                        </a>
                        <a className="social-icon" href="https://www.instagram.com/coradioapp/" target="_blank" rel="noopener noreferrer">
                            <img src="landing/Insta.svg" alt="Instagram" />
                        </a>
                        <a className="social-icon" href="https://fb.me/coradioapp" target="_blank" rel="noopener noreferrer">
                            <img src="landing/Facebook.svg" alt="Facebook" />
                        </a>
                    </div>
                </div>
                <h1 className="hero">
                    Shared Live Radio<br />
            For People at Home
          </h1>
                <Link to="/s/the-covid-club" className="button button-large">Come Listen With Us</Link>
            </div>
        </div>
    );
}

export default LandingPage;
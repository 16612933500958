import React, { useState, useEffect, Fragment } from 'react'
import { animated, useTransition } from 'react-spring'

const timeoutAsync = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

window.id = 0

function NewTrackNotification({ children }) {
  const [refMap] = useState(() => new WeakMap())
  const [cancelMap] = useState(() => new WeakMap())
  const [items, setItems] = useState([])
  const transitions = useTransition(items, item => item.key, {
    from: {
      opacity: 0.5,
      transform: 'scale(0.8) translateY(0px)',
    },
    enter: item => async next => {
      await next({ opacity: 1, transform: 'scale(1) translateY(0px)' })
    },
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await timeoutAsync(2000)
      await next({ opacity: 0, transform: 'scale(1) translateY(8px)' })
    },
    onRest: item => setItems(state => state.filter(i => i.key !== item.key)),
    config: (item, state) =>
      state === 'leave' ? { duration: 150, tension: 125, friction: 20, precision: 0.1 } : { duration: 200 },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => children(albumImage => setItems(state => [...state, { key: window.id++, albumImage }])), [])

  return (
    <Fragment>
      {transitions.map(({ key, item, props: { life, ...style } }) => (
        <animated.div className="track-cover-notification-wrapper" key={key} style={style}>
          {item.albumImage && <div className="track-cover-notification" ref={ref => ref && refMap.set(item, ref)}><img src={item.albumImage} alt='' /></div>}
        </animated.div>
      ))}
    </Fragment>
  )
}

export default NewTrackNotification;